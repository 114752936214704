import React, {ReactElement, useEffect, useRef} from 'react';
import {AdvertSliderProps} from "./AdvertSliderTypes";
import CardVertical from "../../Cards/CardVertical/CardVertical";
import {Options, Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import {Icon} from "../../Icon/Icon";
import s from "./AdvertSlider.module.scss"
import useLike from "../../../hooks/useLike";

const AdvertsSlider = (props: AdvertSliderProps) => {

    const mainRef = useRef<Splide>(null)
    const bulletsRef = useRef<Splide>(null)

    const {likes, like, dislike} = useLike()

    useEffect(() => {
        if (mainRef.current && bulletsRef.current && bulletsRef.current.splide) {
            mainRef.current.sync(bulletsRef.current.splide)
        }
    }, [])

    const cards: Array<ReactElement> = props.adverts.map(advert => {
        return <SplideSlide key={advert.id}>
                    <CardVertical advert={advert} dislike={dislike} like={like}
                               isLike={!!likes.find(id => advert.id === id)}/>

        </SplideSlide>
    })

    const options: Options = {
        rewind: true,
        perPage: 4,
        perMove: 1,
        pagination: true,
        arrows: true,
        gap: "1.25rem",
        padding: { top: 100, bottom: 200 },
        breakpoints: {
            1400 : {
                perPage: 3,
                gap: "1rem",
            },
            1072 : {
                perPage: 2,
                gap: "0.85rem",
            },
            767 : {
                perPage: 1
            }

        }
    }



    return (
        <div className={s.container}>
            <div className={s.title}>{props.title}</div>
            <div className={s.subtitle}>{props.subtitle}</div>
            <Splide hasTrack={false} options={options} >
                <SplideTrack>
                    {cards}
                </SplideTrack>
                <div className="splide__arrows">
                    <div className={s.prev}>
                        <button className={`splide__arrow splide__arrow--prev ${s.button}`}>
                            <Icon name={"arrow_gallery"} color={"black"} size={14}/>
                        </button>
                    </div>
                    <div className={s.next}>
                        <button className={`splide__arrow splide__arrow--next ${s.button} ${s.button_right}`}>
                            <Icon name={"arrow_gallery"} color={"black"} size={14}/>
                        </button>
                    </div>
                </div>
            </Splide>

        </div>
    );
};

export default AdvertsSlider;
