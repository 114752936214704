import React, { useState } from 'react';
import s from './HomeCategories.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { ICategoryRoot } from '../../../models/ICategory';
import SubCategories from '../SubCategoriesGrid/SubCategoriesGrid';

const HomeCategories = ({ rootMenu }: { rootMenu: ICategoryRoot[] }) => {
    const { lang } = useTranslation('common');

    const [activeCategory, setActiveCategory] = useState<string | undefined>();
    const categoryClickHandler = (url: string) => {
        if (activeCategory == url) {
            setActiveCategory('');
        } else {
            setActiveCategory(url);
        }
    };

    return (
        <div className={s.container}>
            <menu className={s.categories}>
                {rootMenu?.map((category) => (
                    <li key={category.id} className={s.categoryItem}>
                        <button
                            className={`${s.categoryButton}  ${
                                activeCategory === category.url
                                    ? s.categoryButton_active
                                    : ''
                            }`}
                            type="button"
                            onClick={() => categoryClickHandler(category.url)}
                        >
                            {category.lang[lang]}
                        </button>
                    </li>
                ))}
            </menu>

            {activeCategory && (
                <SubCategories activeCategory={activeCategory} />
            )}
        </div>
    );
};

export default HomeCategories;
