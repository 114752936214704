import React, { useEffect } from 'react';
import AdvertsSlider from '../../components/Sliders/AdvertsSlider/AdvertsSlider';
import HomeAnnons from '../../components/Home/HomeAnnons/HomeAnnons';
import HomeCategories from '../../components/Home/HomeCategories/HomeCategories';
import s from './Home.module.scss';
import Image from 'next/image';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import ListThunks from '../../redux/list/thunks';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ListActionCreator } from '../../redux/list/actions';
import useTranslation from 'next-translate/useTranslation';
import { Adsense } from '@ctrl/react-adsense';
import { publicApi } from '../../redux/api';
import { backendUrl } from '../../utils/url';
import AdLink from '../../components/AdLink/AdLink';
import useAdaptive from '../../hooks/useAdaptive';

const Home = (props: any) => {
    const { t } = useTranslation('common');
    const { isMobile } = useAdaptive();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(ListThunks.getPremium('', ['on_main_page']));
        dispatch(ListThunks.getLastVisited());
        return () => {
            dispatch(ListActionCreator.setPremium([]));
        };
    }, [dispatch]);

    const adverts = useAppSelector((state) => state.list.premium);
    const lastVisited = useAppSelector((state) => state.list.lastVisited);
    const { data: actualAds, isSuccess } = publicApi.useGetActualAdsQuery({
        limit: 8,
    });

    const { data } = publicApi.useGetAdPlaceQuery({
        type: 'main',
    });

    let googleAds = null;
    let ownAds = null;
    if (!!data) {
        let countGoogleAds = 0;
        if (!!data?.big_banner) {
            countGoogleAds =
                data.big_banner.count - (data.big_banner?.ads?.length || 0);
        }
        googleAds =
            countGoogleAds > 0 &&
            Array.from(Array(countGoogleAds).keys()).map((i) => (
                <div className={s.banner} key={i}>
                    <Adsense
                        client={process.env.NEXT_PUBLIC_SENSE_CLIENT_ID}
                        slot={process.env.NEXT_PUBLIC_SENSE_SLOT}
                        adTest="on"
                    />
                </div>
            ));

        ownAds = data.big_banner?.ads.map((ad) => (
            <div className={s.banner} key={ad.id}>
                <AdLink ad={ad}>
                    <Image
                        src={backendUrl(
                            `storage/${
                                isMobile ? ad.mobile_image : ad.desktop_image
                            }`
                        )}
                        style={{objectFit:'cover'}}
                        fill
                        //    width={1024} height={258}
                        alt={'banner'}
                    />
                </AdLink>
            </div>
        ));
    }

    return (
        <>
            <div className="container">
                <HomeCategories rootMenu={props.rootMenu}/>
                {adverts.length ? (
                    <AdvertsSlider
                        title={t('Premium annons')}
                        subtitle={t('Be inspired by our newest offerings')}
                        adverts={adverts}
                    />
                ) : (
                    ''
                )}
                <HomeAnnons />
                {isSuccess && actualAds.items.length ? (
                    <AdvertsSlider
                        title={t('Actual annons')}
                        subtitle={t('Be inspired by our newest offerings')}
                        adverts={actualAds.items}
                    />
                ) : (
                    ''
                )}

                <span className={s.text}>{t('Ad')}</span>
                {googleAds}
                {ownAds}
                {lastVisited.length ? (
                    <AdvertsSlider
                        title={t('Last visit')}
                        subtitle={t('Pick the perfect choice for your needs')}
                        adverts={lastVisited}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default Home;
